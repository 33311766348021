class window.Form
  constructor: () ->
    @_$form = $('form')

    @_init()

  _init: ->
    @_events()

  _events: () ->

    $(document).on 'keyup', (e) =>
      input = $(e.target)
      form = input.parents('form')
      if form.length
        form.foundation('validateForm')

    $(document).on 'submit', (e) =>
      form = $(e.target)

      form.ajaxSubmit
        type: "POST"
        success: () =>
          eval form.data 'onsubmit'

          @_resetForm(form)

          if form.hasClass('form--contacts')
            result = form.find('.form__result')
            result.addClass 'visible'
            setTimeout ->
              result.removeClass 'visible'
            , 3000
          else
            modal = $('#reveal-success').foundation('open')
            window.app.hideModal(modal)
        error: () =>
          @_resetForm(form)
          if !form.hasClass('form--contacts')
            modal = $('#reveal-error').foundation('open')
            window.app.hideModal(modal)
      return false

    .on 'forminvalid.zf.abide', (e, form) =>
      form.find('[type="submit"]').addClass 'disabled'

    .on 'formvalid.zf.abide', (e, form) =>
      form.find('[type="submit"]').removeClass 'disabled'

  _resetForm: (form) ->
    if form.length
      form[0].reset()
      $('[type="file"]', form).parent('.input-file').find('span').text('Загрузите свой эскиз').attr 'title', 'Загрузите свой эскиз'
      $('.form__element--related', form).css 'display', 'none'